function initDropdown() {
    console.log("TESTAAAAAAAAAAAAA");
    $.HSCore.components.HSHeader.init($('#js-header'));
    $.HSCore.helpers.HSHamburgers.init('.hamburger');
    $('.dropdown-toggle').dropdownHover();

    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
    });

    
}
